import 'bootstrap/dist/css/bootstrap.min.css';

//styles
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { CardInfo } from '../../components/CardInfo';
import { ExtractTable } from '../../components/ExtractTable';
import { PrintingVersion } from '../../components/PrintingVersion';
import { formatMoney, formatNumberMoney } from '../../constants/functions';
import { useUserContext } from '../../context/UserContext';
import { BilletCreationDataProps, emitBilletPDF, getBilletCreationData } from '../../services/billetCreation';
import { BoxCard, ButtonGroup, LinkButton, PageTitleContainer, Title } from './styles';

const BilletCreation = () => {
  const { userData, group, quota, digit } = useUserContext();
  const [error, setError] = useState('');
  const [billetInfo, setBilletInfo] = useState<BilletCreationDataProps>();

  useEffect(() => {
    if (!userData || !group || !quota || !digit) return;

    console.log({ digit });

    const func = async () => {
      try {
        let res = await getBilletCreationData(group + '', '0' + quota, digit);
        if ((res as any)[0] && (res as any)[0].includes('ERRO')) {
          setError((res as any)[1]);
          return;
        }

        res!.boletosEmitidos = res?.boletosEmitidos.filter((boleto) => !!boleto) as any;
        setBilletInfo(res as BilletCreationDataProps);
      } catch (e) {
        console.log(e);
      }
    };
    func();
  }, [userData, group, quota, digit]);
  const handlePDF = async (e: number) => {
    const boleto = billetInfo?.boletosEmitidos[e];
    if (!boleto) return;
    try {
      const res = await emitBilletPDF(group, quota, digit, boleto.codigoBanco, boleto.nossoNumero.replace('-', ''));
      if (res && res.publicLink) window.open(res.publicLink, '_blank');
    } catch (e) {
      console.log(e);
    }
  };

  const disabledIndexes = () => {
    if (!billetInfo || !billetInfo.boletosEmitidos || !Array.isArray(billetInfo.boletosEmitidos)) return;
    const indexes: number[] = [];
    billetInfo?.boletosEmitidos?.forEach((boleto, index: number) => {
      const differenceInDays = moment(new Date()).diff(moment(boleto.dataVencimento, 'DD/MM/YYYY'), 'days');
      if (differenceInDays > 15) indexes.push(index);
    });

    return indexes;
  };
  const pageContent = () => {
    return (
      <>
        <PageTitleContainer>
          <Title>Emissão de Boleto Bancário</Title>
        </PageTitleContainer>
        {Array.isArray(billetInfo?.boletosEmitidos) ? (
          <>
            <div className="row gy-3 gx-4">
              <div className="col-lg-6">
                <BoxCard>
                  <CardInfo title="Bem objeto" info={billetInfo?.cod_nome_bem} />
                </BoxCard>
              </div>
              <div className="col-lg-6">
                <BoxCard>
                  <CardInfo title="Crédito atual" info={formatMoney(billetInfo?.valcredi)} />
                </BoxCard>
              </div>

              <div className="col-lg-6">
                <BoxCard>
                  <CardInfo title="Prazo Cota" info={billetInfo?.prazo_quota + ' meses'} />
                </BoxCard>
              </div>
              <div className="col-lg-6">
                <BoxCard>
                  <CardInfo title="Assembleia" info={billetInfo?.ass_ref + ' - ' + billetInfo?.data_ass_ref} />
                </BoxCard>
              </div>

              <div className="col-lg-12">
                <BoxCard>
                  <ExtractTable
                    title={'Documentos emitidos recentemente - Banco ' + billetInfo?.bco_aviso_mensal}
                    rowsGroup={[
                      'Banco',
                      'Nosso número',
                      'Vencimento',
                      'Assembleia',
                      'Parcelas cobradas',
                      'Valor cobrado',
                      'Emissão',
                      'Tipo Cobrança',
                    ]}
                    withLink={handlePDF}
                    linkIndex={1}
                    indexesDisabled={disabledIndexes()}
                    data={[
                      ...(billetInfo?.boletosEmitidos?.map((boleto) => {
                        const {
                          assembleiaReferencia,
                          codigoBanco,
                          dataEmissao,
                          dataVencimento,
                          horaEmissao,
                          nossoNumero,
                          parcelasCobradas,
                          tipoCobranca,
                          valorCobrado,
                        } = boleto;
                        return [
                          codigoBanco,
                          nossoNumero,
                          dataVencimento,
                          assembleiaReferencia,
                          parcelasCobradas,
                          formatNumberMoney(valorCobrado),
                          dataEmissao + ' ' + horaEmissao,
                          tipoCobranca,
                        ];
                      }) as []),
                    ]}
                  />
                </BoxCard>
              </div>
            </div>
            <ButtonGroup>
              <LinkButton to="/opcoes-de-pagamento">Gerar novo boleto</LinkButton>
            </ButtonGroup>{' '}
          </>
        ) : (
          <>
            <Alert variant={'danger'}>{billetInfo?.boletosEmitidos}</Alert>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {error && <Alert variant={'danger'}>{error}</Alert>}
      {billetInfo && billetInfo.boletosEmitidos && (
        <>
          <PrintingVersion>{pageContent()}</PrintingVersion>
          <div>{pageContent()}</div>
        </>
      )}
    </>
  );
};

export default BilletCreation;
