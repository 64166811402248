import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  Controller,
  CreditCard,
  Envelope,
  ExclamationSquare,
  FileEarmarkCheck,
  FileEarmarkPdf,
  FileEarmarkPlus,
  FileEarmarkRuled,
  FileEarmarkText,
  House,
  People,
  PersonWorkspace,
  Receipt,
} from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { automaticDebitRoutePage } from '../../routes/AutomaticDebit';
import { bidOfferRoutePage } from '../../routes/BidOffer';
import { billetCreationRoutePage } from '../../routes/BilletCreation';
import { billetSendRoutePage } from '../../routes/BilletSend';
import { changeAddressRoutePage } from '../../routes/ChangeAddress';
import { consolidatedPositionRoutePage } from '../../routes/ConsolidatedPosition';
import { currentAccountStatementRoutePage } from '../../routes/CurrentAccountStatement';
import { groupsAvailabilityRoutePage } from '../../routes/GroupsAvailability';
import { quotaInformationRoutePage } from '../../routes/QuotaInformation';
import { homeRoutePage } from '../../routes/home';
import { IconContainer, SidenavMenuListItemDesktop, SidenavMenuListItemMobile } from './styles';
import { assemblyResultsRoutePage } from '../../routes/AssemblyResults';
import { assemblyResultsResumeRoutePage } from '../../routes/AssemblyResultsResume';
import { contractRoutePage } from '../../routes/Contract';
import { membershipProposalRoutePage } from '../../routes/MembershipProposal';
import { documentsRoutePage } from '../../routes/Documents';
import { changePasswordRoutePage } from '../../routes/ChangePassword';

interface PageListProps {
  minimize: boolean;
  hideLabel: boolean;
  onClick?: () => void;
}

const PageList = ({ minimize, hideLabel, onClick }: PageListProps) => {
  const pageList = [
    { title: 'Dashboard', icon: <People />, route: homeRoutePage.route },
    {
      title: 'Extrato da Conta Corrente',
      icon: <Receipt />,
      route: currentAccountStatementRoutePage.route,
    },
    {
      title: 'Resultado das Assembleias',
      icon: <FileEarmarkText />,
      route: assemblyResultsRoutePage.route,
    },
    {
      title: 'Resultado Assembleias Resumido',
      icon: <FileEarmarkPlus />,
      route: assemblyResultsResumeRoutePage.route,
    },
    { title: 'Oferta de Lance', icon: <ExclamationSquare />, route: bidOfferRoutePage.route },
    { title: 'Alteração de Endereço', icon: <House />, route: changeAddressRoutePage.route },
    { title: 'Troca de Senha', icon: <PersonWorkspace />, route: changePasswordRoutePage.route },
    { title: 'Emissão de Boleto', icon: <FileEarmarkText />, route: billetCreationRoutePage.route },
    { title: 'Posição Consolidada', icon: <Controller />, route: consolidatedPositionRoutePage.route },
    {
      title: 'Disponibilidade de Grupos',
      icon: <People />,
      route: groupsAvailabilityRoutePage.route,
    },
    { title: 'Informações de Cotas', icon: <Controller />, route: quotaInformationRoutePage.route },
    //{ title: 'Proposta de Adesão', icon: <FileEarmarkCheck />, route: membershipProposalRoutePage.route },
    { title: 'Impressão do contrato', icon: <FileEarmarkPdf />, route: contractRoutePage.route },
    // { title: 'Alterar Senha', icon: <FileEarmarkText />, route: changePasswordRoutePage.route },
    { title: 'Envio de Boleto', icon: <Envelope />, route: billetSendRoutePage.route },
    //{ title: 'Documentos', icon: <FileEarmarkRuled />, route: documentsRoutePage.route },
    //{ title: 'Débito Automático', icon: <CreditCard />, route: automaticDebitRoutePage.route },
  ];
  const { isMobile } = useGetWindowSize();
  const navigate = useNavigate();
  const location = useLocation();
  if (isMobile) {
    return (
      <div>
        {pageList.map((page) => (
          <SidenavMenuListItemMobile
            active={location.pathname === page.route}
            key={page.title}
            onClick={() => {
              navigate(page.route);
              if (onClick) {
                onClick();
              }
            }}
          >
            <IconContainer>{page.icon}</IconContainer>
            {page.title}
          </SidenavMenuListItemMobile>
        ))}
      </div>
    );
  }

  return (
    <>
      {pageList.map((page) => (
        <OverlayTrigger
          placement="right"
          overlay={
            minimize ? (
              <Tooltip id="tooltip" style={{ marginLeft: '15px' }}>
                {page.title}
              </Tooltip>
            ) : (
              <></>
            )
          }
        >
          <SidenavMenuListItemDesktop
            active={location.pathname === page.route}
            key={page.title}
            onClick={() => {
              navigate(page.route);
            }}
            minimize={minimize}
          >
            <IconContainer>{page.icon}</IconContainer>
            {!hideLabel && page.title}
          </SidenavMenuListItemDesktop>
        </OverlayTrigger>
      ))}
    </>
  );
};
export default PageList;
