import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

export const isLocalEnv = () => {
  return process.env.REACT_APP_ENV === 'local';
};
if (!isLocalEnv()) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  document.addEventListener('contextmenu', (event) => event.preventDefault());

  document.onkeydown = function (e) {
    if (e.keyCode === 123) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
      return false;
    }
    if (e.ctrlKey && e.keyCode === 85) {
      return false;
    }
  };
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
