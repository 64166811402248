import api from '../api';
import { getFromSessionStorage } from '../../constants/functions';
import { Regra } from '../../pages/BidOffer';

export const getOffers = async (gruposCota: any[]) => {
  const url = `/oferta`;
  try {
    const res = (await api.post(
      url,
      {
        selectedOffers: gruposCota,
      },
      {
        headers: { token: getFromSessionStorage('token') },
      }
    )) as OffersRes;
    return res;
  } catch (e) {
    return null;
  }
};
export const calculateOffers = async (offers: any[]) => {
  const url = `/oferta/calcular`;
  try {
    const res = (await api.post(
      url,
      {
        selectedOffers: offers,
      },
      {
        headers: { token: getFromSessionStorage('token') },
      }
    )) as any;
    return res;
  } catch (e) {
    // console.log(e);

    return (e as any).response.data;
  }
};
export const saveOffers = async (offers: any[]) => {
  const url = `/oferta/salvar`;
  try {
    const res = (await api.post(
      url,
      {
        selectedOffers: offers,
      },
      {
        headers: { token: getFromSessionStorage('token') },
      }
    )) as any;
    return res;
  } catch (e) {
    return null;
  }
};

export interface Bid {
  lancePercentualLimitadoMinimo: string;
  lanceValorMaximoGrupo: string;
  '25': string;
  lanceDataAssembleia: string;
  lanceMaxEmbutido: string;
  '26': string;
  '27': string;
  lanceGrupo: string;
  lanceValorLimitadoMaximo: string;
  '28': string;
  lanceFormaContemplacaoQta: string;
  '29': string;
  lanceCota: string;
  lanceValorMediaGrupo: string;
  lanceQtdeMaximoGrupo: string;
  lancePrioridadeRegraQta: string;
  '30': string;
  lanceEmbutidoQta: string;
  '31': string;
  '32': string;
  lanceQtdeMinimoGrupo: string;
  '33': string;
  '34': string;
  lanceValorLimitadoMinimo: string;
  lanceCodRegraApuracao: string;
  lanceTipoLance: string;
  lanceLimitado: string;
  lanceDescricaoRegraApuracao: string;
  lanceQtdeMediaGrupo: string;
  lanceOferta: string;
  regraEmbutido: string;
  lanceOfertaEmValor: string;
  lancePercentualLimitadoMaximo: string;
  lanceValorMinimoGrupo: string;
  lanceCodRegraApuracaoQta: string;
}

export interface OffersRes {
  lances: Bid[];
  regras: Regra[];
}
