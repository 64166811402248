import 'bootstrap/dist/css/bootstrap.min.css';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import { RefAttributes, useEffect, useMemo, useState } from 'react';

import {
  ChevronLeft,
  ChevronRight,
  ExclamationCircle,
  FileEarmarkText,
  HandIndex,
  Receipt,
  SortUp,
} from 'react-bootstrap-icons';
import { PrintingVersion } from '../../components/PrintingVersion';

import { formatMoney } from '../../constants/functions';
import { useUserContext } from '../../context/UserContext';
import { getConsolidatedPosition } from '../../services/consolidatedPosition';
import { getCurrentAccountStatementInfo } from '../../services/currentAccoutStatement';

//styles
import {
  BilletEmissionRedirect,
  BoxCard,
  DeadLineContainer,
  DeadlineDate,
  GroupCota,
  InfoDeadLine,
  InfoWithButton,
  InfoWithColor,
  LinkToQuota,
  PageTitleContainer,
  QuickAccessButton,
  QuickAccessContainer,
  SlideBox,
  SlideContainer,
  SlidesButton,
  StatusSmall,
  Title,
  ValuesSlide,
} from './styles';
import { OverlayTrigger, Tooltip, TooltipProps } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [consolidate, setConsolidate] = useState<any>();

  const cotas = useMemo(() => {
    if (!consolidate) return [];

    return consolidate.contempladas.concat(consolidate.naoContempladas);
  }, [consolidate]);

  const [extract, setExtract] = useState<any>();
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slides: {
      perView: 1,
      spacing: 24,
    },
    breakpoints: {
      '(min-width: 960px)': {
        slides: {
          perView: 2,
          spacing: 24,
        },
      },
      '(min-width: 1400px)': {
        slides: {
          perView: 3,
          spacing: 24,
        },
      },
      '(min-width: 2000px)': {
        slides: {
          perView: 4,
          spacing: 24,
        },
      },
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  const { group, quota, loginResponseData, userData, digit, setGroup, setQuota } = useUserContext();

  console.log({ group, quota, digit });

  useEffect(() => {
    if (!group || !quota) return;

    const func = async () => {
      try {
        const res1 = await getCurrentAccountStatementInfo(group, quota);
        setExtract(res1);
        if (!loginResponseData) return;

        const groupQuota = loginResponseData.consorciados
          .map((consorciado) => consorciado.grupo + '/' + consorciado.cota)
          .join(',');
        const res2 = await getConsolidatedPosition(groupQuota);
        setConsolidate(res2);
      } catch (e) {
        console.log(e);
      }
    };
    func();
  }, [group, quota, digit, loginResponseData]);
  const renderTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & RefAttributes<HTMLDivElement>) => (
    <Tooltip id="button-tooltip" {...props}>
      Emitir Boleto
    </Tooltip>
  );

  const pageContent = () => {
    return (
      <>
        <QuickAccessContainer className="mb-4">
          <h2>Acesso rápido</h2>

          <div className="row g-3 g-xl-4">
            <div className="col-sm-6 col-lg-3">
              <QuickAccessButton to="/extrato-de-conta-corrente">
                <Receipt size={40} /> Extrato da conta corrente
              </QuickAccessButton>
            </div>
            <div className="col-sm-6 col-lg-3">
              <QuickAccessButton to="/oferta-de-lance">
                <HandIndex size={40} /> Oferta de Lance
              </QuickAccessButton>
            </div>
            <div className="col-sm-6 col-lg-3">
              <QuickAccessButton to="/emissao-de-boleto">
                <FileEarmarkText size={40} /> Emissão de Boleto
              </QuickAccessButton>
            </div>
            <div className="col-sm-6 col-lg-3">
              <QuickAccessButton to="/posicao-consolidada">
                <SortUp size={40} /> Posição Consolidada
              </QuickAccessButton>
            </div>
          </div>
        </QuickAccessContainer>

        <PageTitleContainer>
          <Title>Dashboard</Title>
        </PageTitleContainer>

        <div className="row g-4">
          <div className="col-xl-6">
            <BoxCard>
              <GroupCota>
                {group.padStart(4, '0')} / {quota.padStart(4, '0')}.{digit}
              </GroupCota>

              <div className="d-flex flex-wrap gap-4 justify-content-between">
                <InfoWithColor color="#28A745">
                  <div className="status">
                    <span className="tabBigWithColor" />
                    <h5>Total Pago</h5>
                  </div>
                  <h3>{formatMoney(extract.total_pagto)}</h3>
                  <p>{extract.percamor}%</p>
                </InfoWithColor>

                <InfoWithColor color="#ADB5BD">
                  <div className="status">
                    <span className="tabBigWithColor" />
                    <h5>Parcela Atual</h5>
                  </div>
                  <h3>{formatMoney(extract.totalpagar)}</h3>
                  <p>
                    <strong>{extract.nroPclAtual}</strong>/{extract.prazo_quota}
                  </p>

                  <InfoWithButton>
                    <div className="info">
                      <ExclamationCircle className="icon" color="#664D03" size={22} />
                      <small>
                        <strong>{extract.abertos.length}</strong> parcelas em atraso ou a vencer
                      </small>
                    </div>
                    <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                      <NavLink to={'/emissao-de-boleto'}>
                        <BilletEmissionRedirect>
                          <FileEarmarkText size={22} />
                        </BilletEmissionRedirect>
                      </NavLink>
                    </OverlayTrigger>
                  </InfoWithButton>
                </InfoWithColor>

                <InfoWithColor color="#DC3545">
                  <div className="status">
                    <span className="tabBigWithColor" />
                    <h5>Saldo Devedor</h5>
                  </div>
                  <h3>{formatMoney(extract.saldodevedor)}</h3>
                  <p>{extract.percdeve}%</p>
                </InfoWithColor>
              </div>
            </BoxCard>
          </div>

          <div className="col-xl-6">
            <BoxCard>
              <DeadLineContainer>
                <DeadlineDate>
                  <div>
                    <h5>Próximo Vencimento</h5>

                    <InfoDeadLine>
                      <span>Ass.{extract.assembleias[0].assembleia}</span>
                      <h3>{extract.assembleias[0].vencimento}</h3>
                    </InfoDeadLine>
                  </div>

                  <div className="dates">
                    <div className="date">
                      <small>{extract.assembleias[1].vencimento}</small>
                    </div>
                    <div className="date">
                      <small>{extract.assembleias[2].vencimento}</small>
                    </div>
                  </div>
                </DeadlineDate>

                <DeadlineDate>
                  <div>
                    <h5>Próxima Assembleia</h5>

                    <InfoDeadLine>
                      <span>Ass. {extract.assembleias[0].assembleia}</span>
                      <h3>{extract.assembleias[0].data}</h3>
                    </InfoDeadLine>
                  </div>

                  <div className="dates">
                    <div className="date">
                      <small>{extract.assembleias[1].data}</small>
                    </div>
                    <div className="date">
                      <small>{extract.assembleias[2].data}</small>
                    </div>
                  </div>
                </DeadlineDate>
              </DeadLineContainer>
            </BoxCard>
          </div>
        </div>

        <SlideContainer>
          <div ref={sliderRef} className="keen-slider">
            {cotas.map((extract: any) => (
              <SlideBox className="keen-slider__slide">
                <div className="d-flex align-items-center justify-content-between">
                  <h4>
                    {extract.grupo?.padStart(4, '0')} / {extract.cota?.padStart(4, '0')}
                  </h4>
                  <LinkToQuota
                    onClick={() => {
                      setGroup(extract.grupo);
                      setQuota(extract.cota);
                    }}
                  >
                    Ir para cota
                  </LinkToQuota>
                </div>

                <ValuesSlide>
                  <div>
                    <StatusSmall>
                      <span className="tabBigWithColor" />
                      <small>Valor Parcela</small>
                    </StatusSmall>
                    <h5>{formatMoney(extract.valorParcela)}</h5>
                  </div>

                  <div>
                    <StatusSmall>
                      <span className="tabBigWithColor" />
                      <small>Saldo Devedor</small>
                    </StatusSmall>
                    <h5>{formatMoney(extract.saldoDevedor)}</h5>
                    <small>{extract.percentualDevedor}%</small>
                  </div>

                  <div>
                    <StatusSmall>
                      <span className="tabBigWithColor" />
                      <small>Total Pago</small>
                    </StatusSmall>
                    <h5>{formatMoney(extract.saldoPago)}</h5>
                    <small>{extract.percentualPago}%</small>
                  </div>
                </ValuesSlide>
              </SlideBox>
            ))}
          </div>
        </SlideContainer>

        {loaded && instanceRef.current && (
          <SlidesButton>
            <button
              className="left"
              onClick={() => {
                instanceRef.current?.prev();
                setCurrentSlide((prevState) => prevState - 1);
              }}
              disabled={currentSlide === 0}
            >
              <ChevronLeft size={16} />
            </button>
            <button
              className="right"
              onClick={() => {
                instanceRef.current?.next();
                setCurrentSlide((prevState) => prevState + 1);
              }}
              disabled={
                instanceRef.current.track.details?.slides.length < 3
                  ? true
                  : currentSlide === instanceRef.current.track.details?.slides.length - 3
              }
            >
              <ChevronRight size={16} />
            </button>
          </SlidesButton>
        )}
      </>
    );
  };

  return (
    <>
      {loginResponseData && userData && extract && consolidate && (
        <>
          <PrintingVersion>{pageContent()}</PrintingVersion>

          <div>{pageContent()}</div>
        </>
      )}
    </>
  );
};

export default Home;
